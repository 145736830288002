<template>

    <div class="sports-links" style="width: 28%">
        <router-link tag="a" to="/board" class="link" active-class="active_sports_link">자유게시판</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" to="/analysis" class="link" active-class="active_sports_link">분석게시판</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" to="/event" class="link" active-class="active_sports_link">이벤트</router-link>
    </div>
</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import {RECEIVE_DISPLAY_POSTION} from "../../store/mutation-types";

    export default {
        name: "BoardLinksComp",
        props: {
        },
        data() {
            return {
                sportsConst,
            }
        },
        methods: {

        },
    }
</script>

<style scoped>

</style>